"use client";

import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon, TableCellsIcon } from "@heroicons/react/16/solid";
import { Button, Text } from "@tremor/react";
import type { Table } from "@tanstack/react-table";
import type { GroupBase, SingleValueProps } from "react-select";
import { components } from "react-select";
import { Select } from "@/shared/ui";
type Props = {
  table: Table<any>;
  // TODO: Add refresh button
  // allowRefresh?: boolean;
};
interface OptionType {
  value: string;
  label: string;
}
const SingleValue = ({
  children,
  ...props
}: SingleValueProps<OptionType, false, GroupBase<OptionType>>) => <components.SingleValue {...props} data-sentry-element="unknown" data-sentry-component="SingleValue" data-sentry-source-file="TablePagination.tsx">
    {children}
    <TableCellsIcon className="w-4 h-4 ml-2" data-sentry-element="TableCellsIcon" data-sentry-source-file="TablePagination.tsx" />
  </components.SingleValue>;
export function TablePagination({
  table
}: Props) {
  const pageIndex = table.getState().pagination.pageIndex;
  const pageCount = table.getPageCount();
  return <div className="flex justify-between items-center" data-sentry-component="TablePagination" data-sentry-source-file="TablePagination.tsx">
      <Text data-sentry-element="Text" data-sentry-source-file="TablePagination.tsx">
        Showing {pageCount === 0 ? 0 : pageIndex + 1} of {pageCount}
      </Text>
      <div className="flex gap-1">
        <Select components={{
        SingleValue
      }} value={{
        value: table.getState().pagination.pageSize.toString(),
        label: table.getState().pagination.pageSize.toString()
      }} onChange={selectedOption => table.setPageSize(Number(selectedOption!.value))} options={[{
        value: "10",
        label: "10"
      }, {
        value: "20",
        label: "20"
      }, {
        value: "50",
        label: "50"
      }, {
        value: "100",
        label: "100"
      }]} menuPlacement="top" data-sentry-element="Select" data-sentry-source-file="TablePagination.tsx" />
        <div className="flex">
          <Button className="pagination-button" icon={ChevronDoubleLeftIcon} onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} size="xs" color="gray" variant="secondary" data-sentry-element="Button" data-sentry-source-file="TablePagination.tsx" />
          <Button className="pagination-button" icon={ChevronLeftIcon} onClick={table.previousPage} disabled={!table.getCanPreviousPage()} size="xs" color="gray" variant="secondary" data-sentry-element="Button" data-sentry-source-file="TablePagination.tsx" />
          <Button className="pagination-button" icon={ChevronRightIcon} onClick={table.nextPage} disabled={!table.getCanNextPage()} size="xs" color="gray" variant="secondary" data-sentry-element="Button" data-sentry-source-file="TablePagination.tsx" />
          <Button className="pagination-button" icon={ChevronDoubleRightIcon} onClick={() => table.setPageIndex(pageCount - 1)} disabled={!table.getCanNextPage()} size="xs" color="gray" variant="secondary" data-sentry-element="Button" data-sentry-source-file="TablePagination.tsx" />
        </div>
        {/* TODO: Add refresh button */}
      </div>
    </div>;
}