"use client";

import { ErrorComponent } from "@/shared/ui";
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <ErrorComponent error={error} data-sentry-element="ErrorComponent" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
}