import clsx from "clsx";
import "./vertical-rounded-list.css";
export function VerticalRoundedList({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <div className={clsx("flex flex-col vertical-rounded-list", className)} data-sentry-component="VerticalRoundedList" data-sentry-source-file="VerticalRoundedList.tsx">
      {children}
    </div>;
}