import { Button, Card } from "@tremor/react";
import { CircleStackIcon } from "@heroicons/react/24/outline";
export function EmptyStateCard({
  title,
  description,
  buttonText,
  onClick,
  className
}: {
  title: string;
  description: string;
  buttonText: string;
  onClick: (e: React.MouseEvent) => void;
  className?: string;
}) {
  return <Card className={`sm:mx-auto w-full max-w-5xl ${className ? className : ""}`} data-sentry-element="Card" data-sentry-component="EmptyStateCard" data-sentry-source-file="EmptyStateCard.tsx">
      <div className="text-center">
        <CircleStackIcon className="mx-auto h-7 w-7 text-tremor-content-subtle dark:text-dark-tremor-content-subtle" aria-hidden={true} data-sentry-element="CircleStackIcon" data-sentry-source-file="EmptyStateCard.tsx" />
        <p className="mt-4 text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
          {title}
        </p>
        <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
          {description}
        </p>
        <Button className="mt-4" color="orange" onClick={onClick} data-sentry-element="Button" data-sentry-source-file="EmptyStateCard.tsx">
          {buttonText}
        </Button>
      </div>
    </Card>;
}