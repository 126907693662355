import React from "react";
import Image from "next/image";
import "./logo-error.css";
export interface KeepLogoErrorProps {
  width?: number;
  height?: number;
}
export const KeepLogoError = ({
  width = 200,
  height = 200
}: KeepLogoErrorProps) => {
  return <div className="wrapper -my-10" style={{
    width,
    height
  }} data-sentry-component="KeepLogoError" data-sentry-source-file="KeepLogoError.tsx">
      <div className="logo-container">
        <svg width="100%" height="100%" viewBox="0 0 200 200" data-sentry-element="svg" data-sentry-source-file="KeepLogoError.tsx">
          <defs data-sentry-element="defs" data-sentry-source-file="KeepLogoError.tsx">
            <filter id="tvNoise" data-sentry-element="filter" data-sentry-source-file="KeepLogoError.tsx">
              <feTurbulence type="fractalNoise" baseFrequency="0.1" numOctaves="4" seed="1" stitchTiles="stitch" data-sentry-element="feTurbulence" data-sentry-source-file="KeepLogoError.tsx">
                <animate attributeName="seed" from="1" to="10" dur="0.1s" repeatCount="indefinite" data-sentry-element="animate" data-sentry-source-file="KeepLogoError.tsx" />
              </feTurbulence>
              <feDisplacementMap in="SourceGraphic" scale="5" data-sentry-element="feDisplacementMap" data-sentry-source-file="KeepLogoError.tsx" />
            </filter>

            <filter id="redChannel" data-sentry-element="filter" data-sentry-source-file="KeepLogoError.tsx">
              <feColorMatrix type="matrix" values="1.5 0 0 0 0.2  0 0 0 0 0  0 0 0 0 0  0 0 0 0.8 0" data-sentry-element="feColorMatrix" data-sentry-source-file="KeepLogoError.tsx" />
              <feOffset dx="0" dy="0" data-sentry-element="feOffset" data-sentry-source-file="KeepLogoError.tsx">
                <animate attributeName="dx" values="0;-10;0" dur="4s" keyTimes="0;0.96;1" repeatCount="indefinite" data-sentry-element="animate" data-sentry-source-file="KeepLogoError.tsx" />
              </feOffset>
            </filter>

            <filter id="greenChannel" data-sentry-element="filter" data-sentry-source-file="KeepLogoError.tsx">
              <feColorMatrix type="matrix" values="0 0 0 0 0  0 1.5 0 0 0.2  0 0 0 0 0  0 0 0 0.8 0" data-sentry-element="feColorMatrix" data-sentry-source-file="KeepLogoError.tsx" />
              <feOffset dx="0" dy="0" data-sentry-element="feOffset" data-sentry-source-file="KeepLogoError.tsx">
                <animate attributeName="dx" values="0;5;0" dur="4s" keyTimes="0;0.96;1" repeatCount="indefinite" data-sentry-element="animate" data-sentry-source-file="KeepLogoError.tsx" />
                <animate attributeName="dy" values="0;-8;0" dur="4s" keyTimes="0;0.96;1" repeatCount="indefinite" data-sentry-element="animate" data-sentry-source-file="KeepLogoError.tsx" />
              </feOffset>
            </filter>
          </defs>

          <g style={{
          mixBlendMode: "screen"
        }} data-sentry-element="g" data-sentry-source-file="KeepLogoError.tsx">
            <foreignObject width="100%" height="100%" style={{
            filter: "url(#redChannel)"
          }} data-sentry-element="foreignObject" data-sentry-source-file="KeepLogoError.tsx">
              <div>
                <Image src="/keep.svg" alt="Keep Logo" width={width} height={height} className="w-full h-full" data-sentry-element="Image" data-sentry-source-file="KeepLogoError.tsx" />
              </div>
            </foreignObject>
            <foreignObject width="100%" height="100%" style={{
            filter: "url(#greenChannel)"
          }} data-sentry-element="foreignObject" data-sentry-source-file="KeepLogoError.tsx">
              <div>
                <Image src="/keep.svg" alt="Keep Logo" width={width} height={height} className="w-full h-full" data-sentry-element="Image" data-sentry-source-file="KeepLogoError.tsx" />
              </div>
            </foreignObject>
            <foreignObject width="100%" height="100%" style={{
            filter: "url(#tvNoise)"
          }} data-sentry-element="foreignObject" data-sentry-source-file="KeepLogoError.tsx">
              <div>
                <Image src="/keep.svg" alt="Keep Logo" width={width} height={height} className="w-full h-full" data-sentry-element="Image" data-sentry-source-file="KeepLogoError.tsx" />
              </div>
            </foreignObject>
          </g>
        </svg>
      </div>
    </div>;
};